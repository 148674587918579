<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row v-if="currentRouteName === 'reconcileReceivedList'">
                  <b-col><h6 class="mb-0">Kết quả đối soát</h6></b-col>
                  <b-col class="text-right">
                    <div>
                      <button
                        v-if="
                          [
                            'NAPAS',
                            'VPB',
                            'BIDV',
                            'VCB',
                            'ONEPAY',
                            'NAPAS_CASHOUT',
                          ].includes(filter.partner)
                        "
                        type="button"
                        @click="exportExcel()"
                        class="btn btn-warning ml-3"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <span>Xuất dữ liệu</span>
                      </button>
                      <button
                        v-if="filter.partner === 'MB'"
                        type="button"
                        @click="exportFile('general')"
                        class="btn btn-warning ml-3"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <span>Xuất file tổng hợp</span>
                      </button>
                      <button
                        v-if="filter.partner === 'MB'"
                        type="button"
                        @click="exportFile('false')"
                        class="btn btn-warning ml-3"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                        <span>Xuất file chênh lệch</span>
                      </button>
                      <button
                        v-if="
                          filter.partner === 'VPB' &&
                          (!fileInfo.project || fileInfo.project !== 'PAYGATE')
                        "
                        type="button"
                        @click="resyncTransactionWallet()"
                        class="btn btn-info ml-3"
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                        <span>Đồng bộ trạng thái</span>
                      </button>
                      <!--                      <button-->
                      <!--                        v-if="-->
                      <!--                          [-->
                      <!--                            'STB_CYBS',-->
                      <!--                            'VPB',-->
                      <!--                            'VPB_CYBS',-->
                      <!--                            'BIDV',-->
                      <!--                            'VCB',-->
                      <!--                            'ONEPAY',-->
                      <!--                            'VNPAY_PORTONE',-->
                      <!--                            'ZALOPAY_WALLET',-->
                      <!--                          ].includes(filter.partner)-->
                      <!--                        "-->
                      <!--                        type="button"-->
                      <!--                        @click="syncReconcile()"-->
                      <!--                        class="btn btn-info ml-3"-->
                      <!--                        :disabled="fileInfo.is_reconciled"-->
                      <!--                      >-->
                      <!--                        <i class="fa fa-refresh" aria-hidden="true"></i>-->
                      <!--                        <span>Đối soát</span>-->
                      <!--                      </button>-->
                    </div>
                  </b-col>
                </b-row>
              </template>
              <div class="filter-transaction">
                <div>
                  <div class="row mb-8">
                    <div class="col-3">
                      <label>Ngày đối soát:</label>
                      <div
                        class="input-daterange input-group"
                        id="kt_datepicker"
                      >
                        <input
                          type="date"
                          v-model="filter.date_from"
                          class="form-control datatable-input"
                          name="start"
                          placeholder="Từ"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-3">
                      <label>Đối tác:</label>
                      <select
                        disabled
                        v-model="filter.partner"
                        class="form-control"
                      >
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in partners"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-3">
                      <label>Loại giao dịch:</label>
                      <select
                        v-model="filter.payment_type_refund_or_payment"
                        class="form-control"
                      >
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in payment_types"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="col-3"
                      v-if="currentRouteName === 'reconcileReceivedList'"
                    >
                      <label>Trạng thái đối soát:</label>
                      <select v-model="filter.is_match" class="form-control">
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in is_matchs"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div
                      class="col-3"
                      v-if="currentRouteName === 'reconcileReceivedList'"
                    >
                      <label>Kết quả so khớp:</label>
                      <select
                        v-model="filter.match_status"
                        class="form-control"
                      >
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in match_status"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-3">
                      <label>Mã giao dịch 9Pay:</label>
                      <input class="form-control" v-model="filter.order_code" />
                    </div>
                    <div class="col-3">
                      <label>Mã tham chiếu:</label>
                      <input
                        class="form-control"
                        v-model="filter.reference_no"
                      />
                    </div>
                    <div class="col-3">
                      <label>Yêu cầu xử lý sai lệch:</label>
                      <select
                        v-model="filter.request_code"
                        class="form-control"
                      >
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in request_code"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-8">
                    <div class="col-lg-12 text-right">
                      <button
                        class="btn btn-primary btn-primary--icon"
                        @click="handleFilter"
                      >
                        <span>
                          <i class="la la-search" aria-hidden="true"></i>
                          <span>Tìm kiếm</span>
                        </span>
                      </button>
                      <button
                        class="ml-2 btn btn-secondary btn-secondary--icon"
                        @click="cancelFilter"
                      >
                        <span>
                          <i class="fa fa-ban" aria-hidden="true"></i>
                          <span>Huỷ</span>
                        </span>
                      </button>
                      <a
                        class="ml-2 btn btn-secondary btn-secondary--icon"
                        href="/#/auto-reconcile/received/aggregate"
                      >
                        <span>
                          <i class="fa fa-arrow-left" aria-hidden="true"></i>
                          <span>Quay lại</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <b-table
                  :fields="fields"
                  :items="items"
                  details-td-class="datatable-cell"
                  hover
                  table-class="datatable-table"
                  tbody-tr-class="datatable-row"
                  thead-class="datatable-head"
                  thead-tr-class="datatable-row"
                >
                  <template #cell(stt)="data">
                    <div>
                      {{ data.index + 1 }}
                    </div>
                  </template>
                  <template #cell(payment_status)="data">
                    <div>
                      {{ data.value ? data.value : "Chưa có" }}
                    </div>
                  </template>
                  <template #cell(is_match)="data">
                    <div>
                      {{ data.value === 3 ? "Khớp" : "Không khớp" }}
                    </div>
                  </template>
                  <template #cell(result_match)="data">
                    <div>
                      {{
                        match_status.find(
                          (item) => item.value === data.item.is_match
                        ).name || "Chưa có"
                      }}
                    </div>
                  </template>
                  <template #cell(transaction_detail)="data">
                    <div>
                      {{ data.value.request_code }}
                    </div>
                  </template>
                  <template #cell(transaction_type)="data">
                    <div>
                      {{
                        data.item.transaction_type === "PURCHASE"
                          ? data.item.fund !== "VPB"
                            ? "Thanh toán"
                            : "Nạp tiền"
                          : data.item.fund !== "VPB"
                          ? "Hoàn tiền"
                          : "Rút tiền"
                      }}
                    </div>
                  </template>
                  <template #cell(type)>Thành công (TC) </template>
                  <template #cell(action)="data">
                    <a
                      :href="`#/auto-reconcile/${filter.file_type}/show/${data.item.id}?${getCurrentQuery}`"
                      target="_blank"
                      >Chi tiết</a
                    >
                  </template>
                </b-table>
                <div class="mt-3">
                  <b-pagination-nav
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    use-router
                    first-number
                    last-number
                    align="right"
                  ></b-pagination-nav>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  name: "Reconcile",
  mixins: [Common],
  components: {},
  data() {
    return {
      fileInfo: {},
      filter: {
        date_from: null,
        partner: "",
        payment_type_refund_or_payment: "",
        is_match: "",
        match_status: "",
        order_code: "",
        reference_no: "",
        request_code: "",
      },
      request_code: [
        { value: 114, name: "Giao dịch hoàn bán phẩm" },
        { value: 115, name: "Giao dịch hoàn toàn phẩm" },
        { value: 117, name: "Đòi bồi hoàn" },
      ],
      match_status: [
        { value: 0, name: "Không khớp" },
        { value: 1, name: "Đối tác thành công, 9Pay không" },
        { value: 2, name: "9Pay thành công, đối tác không" },
        { value: 3, name: "Khớp đúng" },
        { value: 4, name: "Không khớp phí" },
      ],
      payment_types: [
        { value: "PURCHASE", name: "Thanh toán" },
        { value: "REFUND", name: "Hoàn tiền" },
      ],
      is_matchs: [
        { value: 1, name: "Đã so khớp" },
        { value: 0, name: "Không khớp" },
      ],
      partners: [
        { value: "NAPAS", name: "NAPAS" },
        { value: "MB", name: "MB" },
        { value: "VPB", name: "VPB" },
        { value: "BIDV", name: "BIDV" },
        { value: "VCB", name: "VCB" },
        { value: "ONEPAY", name: "ONEPAY" },
        { value: "9PAY", name: "9PAY" },
        { value: "PAYGATE", name: "PAYGATE" },
        { value: "VCCB", name: "BVB" },
        { value: "MSB", name: "MSB" },
        { value: "VPB_CYBS", name: "VPB_CYBS" },
        { value: "NAPAS_CASHOUT", name: "NAPAS_CASHOUT" },
        { value: "STB_CYBS", name: "STB_CYBS" },
      ],
      modalId: "test-export",
      id: null,
      fields: [
        {
          key: "stt",
          label: "STT",
        },
        {
          key: "transaction_type",
          label: "Loại giao dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "mid_code",
          label: "MID",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "order_code",
          label: "Mã giao dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "partner_code",
          label: "Mã tham chiếu",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "payment_status",
          label: "Trạng thái giao dịch tại 9pay",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "is_match",
          label: "Trạng thái đối soát",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "result_match",
          label: "Kết quả so khớp",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "transaction_detail",
          label: "Yêu cầu xử lý sai lệch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Thao tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
    getCurrentQuery() {
      return new URLSearchParams(this.$route.query).toString();
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.getList();
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kết quả đối soát" }]);
    this.filter = { ...this.filter, ...this.$route.query };

    this.cancelFilter();
  },
  methods: {
    exportExcel() {},
    exportFile() {},
    async syncReconcile() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.receivedSyncTransaction(
          this.$route.params.id
        );
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        if (body.status) {
          this.notifyAlert("success", "Kết quả sẽ cập nhật trong giây lát");
          this.$bvModal.hide(this.modalId);
        } else {
          this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại");
        }
      } catch (e) {
        this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    async resyncTransactionWallet() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.syncStatusTransactionWallet(
          this.$route.params.id
        );
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        if (body.status) {
          this.notifyAlert("success", "Kết quả sẽ cập nhật trong giây lát");
          this.$bvModal.hide(this.modalId);
        } else {
          this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại");
        }
      } catch (e) {
        this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === "") {
          delete params[key];
        }
      });
      return params;
    },
    async getList() {
      try {
        if (this.currentRouteName === "reconcileReceivedList") {
          this.filter.file_type = "received";
        }
        if (this.currentRouteName === "requestReceivedList") {
          this.filter.file_type = "request";
        }
        if (this.currentRouteName === "responseReceivedList") {
          this.filter.file_type = "response";
        }
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;

        // if (this.currentRouteName === "requestReceivedList" || this.currentRouteName === "responseReceivedList") {
        //   params.date_from = "";
        // }

        let response = await CmsRepository.getTransactionList(
          this.$route.params.id,
          params
        );
        this.$bus.$emit("show-loading", false);
        this.items = response.data.data.list.data;

        if (this.items.length) {
          this.items.forEach((value) => {
            value[
              "detail"
            ] = `#/received/list/?id=${value.id}?partner=VCB&date_from=2024-10-28&is_reconciled=0`;
          });
        }
        this.fileInfo = response.data.data.file_info;

        this.paginate.total = response.data.data.list.total;
        this.paginate.totalPage = response.data.data.list.last_page;
      } catch (e) {
        this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    handleFilter() {
      // router
      //   .push({
      //     path: "/auto-reconcile/received/aggregate",
      //     query: this.filter,
      //   })
      //   .catch(() => {});
      this.getList();
    },
    cancelFilter() {
      this.filter = {
        date_from: this.filter.date_from,
        partner: this.filter.partner,
        payment_type_refund_or_payment: "",
        is_match: "",
        match_status: "",
        order_code: "",
        reference_no: "",
        request_code: "",
      };
      this.getList();
    },
  },
};
</script>
